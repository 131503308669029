import React, {useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import {useNavigate} from 'react-router-dom'
import Protected from "./components/protected/protected"
import './App.css';
import {CheckIsAuthorized, setAuthorized, setUnauthorized, SetValue, GetValue} from './utils/storage.js'
import { globalData, pages } from "./utils/global.js";
import Cabinet from "./components/cabinet/index.js";
import Logout from "./pages/logout/index";
import Rooter from "./pages/root/index.js";
import Signin from "./pages/signin";
import { useLocation } from 'react-router-dom';
function App() {
  const [authorize, setAuthorize] = useState(false);
  const navigate = useNavigate()

  const Deauthorize = () =>{
    setUnauthorized()
    setAuthorize(false)
  }
  const Authorize = () =>{
    setAuthorized()
    setAuthorize(true)
  }
  useEffect(()=>{
    setAuthorize(CheckIsAuthorized());
  })
  useEffect(()=>{
    navigate(JSON.parse(window.sessionStorage.getItem('lastRoute') || '{}'))
  },[])
  const location = useLocation();

  useEffect(() => {
    window.sessionStorage.setItem('lastRoute', JSON.stringify(window.location.pathname))
  }, [location])
  useEffect(()=>{
    globalData.Authorize = Authorize;
    globalData.Deauthorize = Deauthorize
    console.log(pages)
  },[])
  const ArrayTwoToOneDimension = (array, key) => {
    let result = []
    array.map((item) => {
      if (item.children){
        item.children.map((child) => {
          result.push(child)
        })
      } else {
        result.push(item);
      }
    })
    return result
  }
  return (
    <div className="App">
      <div>
        <Routes>
              <Route 
                path = {"/"}
                element = {
                  <Signin />
                }
              />
              {
                pages && ArrayTwoToOneDimension(pages).map((page)=>{
                  return <Route 
                          path = {page.route}  
                          element = {    
                            <Protected isLoggedIn={authorize}>
                              <Cabinet title={page.label}>
                                {page.component}
                              </Cabinet>
                              <Rooter />
                            </Protected>
                          }/>
                  })
              }
              <Route 
                path = {"/logout"}
                element = {
                  <Logout Deauthorize={Deauthorize}/>
                }
              />
          </Routes>
      </div>

    </div>
  );
}

export default App;
