import {get, post} from "./apiService"

export function GetAllTests(){
    return post("/test/getAll")
}
export function CreateTest(data){
    return post("/test/create", data)
}
export function DeleteTest(data){
    return post("/test/remove", data)
}