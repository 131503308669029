import React, { useState } from 'react';
import 'antd/dist/antd.css';
import './style.css';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  message,
} from 'antd';
import Header from "../../components/header"
import {CreateEvent} from "../../api/eventsService"
import {useNavigate} from 'react-router-dom'
import {prepareFormData} from '../../utils/Helpers/formatter'
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const EventCreateForm = () => {
  
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const data = await CreateEvent(values)
    if (data) {
      if (data) {
        message.success(`Упражнение загружено успешно!`);
        navigate("/events")
      } else {
        message.error(`Ошибка загрузки!`);
      }
    }

    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {

    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <Form className='UserCreateForm-main'
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
      >
        <Form.Item label="Название" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Тип" name="type">
          <Input />
        </Form.Item>
        <Form.Item label="Содержимое" name="text">
          <Input />
        </Form.Item>
        <Form.Item >
          <Button type="primary" htmlType="submit">Создать</Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default EventCreateForm;