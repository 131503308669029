import {get, post, postFormData} from "./apiService"

export function GetAllGymnasticWithContent(){
    return post("/gymnastic/getAllGymnasticsByAdmin")
}

export function CreateGymnastic(data){
    return postFormData("/gymnastic/create", data)
}

export function RemoveGymnastic(data){
    return post("/gymnastic/removeGymnasticByAdmin", data)
}

export function UpdateGymnastic(data){
    return postFormData("/gymnastic/updateGymnasticByAdmin", data)
}
