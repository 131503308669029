import React, { useState } from 'react';
import 'antd/dist/antd.css';
import './style.css';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
} from 'antd';
import Header from "../../components/header"

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const EventEditForm = ({setEditModal}) => {

  const createValidData = (data) => {
    const formData = new FormData();
    formData.append("type", "patient")
  }
  return (
    <div className='UserEditForm-back'>
      <Form className='UserEditForm-main'
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
      >
        <Form.Item label="Название">
          <Input />
        </Form.Item>
        <Form.Item label="Тип">
          <Input />
        </Form.Item>
        <Form.Item label="Текст">
          <Input />
        </Form.Item>
      </Form>
    </div>
  );
};

export default EventEditForm;