import { Simulate } from "react-dom/test-utils"
import CreateGymnasticVariantBase from "../pages/createGymnasticVariantBase"
import EventCreate from "../pages/eventCreate"
import TestCreate from "../pages/testCreate"

const { default: Signin } = require("../pages/signin")
const { default: Clients } = require("../pages/clients")
const { default: Home } = require("../pages/home")
const { default: CreateClient } = require("../pages/clientCreate")
const { default: Doctors } = require("../pages/doctors")
const { default: DoctorCreate } = require("../pages/doctorCreate")
const { default: BaseCourses } = require("../pages/baseCourses")
const { default: BaseCourseCreate } = require("../pages/baseCourseCreate")
const { default: Gymnastics } = require("../pages/gymnastics")
const { default: GymnasticCreate } = require("../pages/gymnasticCreate")
const { default: UserCourses } = require("../pages/userCourses")
const { default: CourseCreate } = require("../pages/courseCreate")
const { default: GymnasticVariants } = require("../pages/gymnasticsVariants")
const { default: CreateGymnasticVariant } = require("../pages/createGymnasticVariant")
const { default: Tests } = require("../pages/tests")
const { default: Events } = require("../pages/events")

export const globalData = {
    Deauthorize:null,
    Authorize:null
}
export const pages = [

    {label:"Главная", key: "Home", route: "/home", component: <Home />},

    {label:"Пользователи", key:"Users_Section", route:"/users", children: [
        {label:"Клиенты",key: "Clients", route: "/clients", component: <Clients />},
        {label:"Создать клиента",key: "CreateClient", route: "/create_client", component: <CreateClient />},
    
        {label:"Инструктора",key: "Doctors", route: "/doctors", component: <Doctors />},
        {label:"Создать инструктора",key: "CreateDoctor", route: "/create_doctor", component: <DoctorCreate />},
    ]},

    {label:"Курсы", key:"Courses_Section", route:"/courses", children: [
        {label:"Базовые курсы",key: "BaseCourses", route: "/base_courses", component: <BaseCourses />},
        {label:"Создать базовый курс",key: "CreateBaseCourse", route: "/create_base_course", component: <BaseCourseCreate />},
    
        {label:"Пользовательские курсы",key: "UserCourses", route: "/courses", component: <UserCourses />},
    ]},

    {label:"Упражнения", key:"Gymnastics_Section", route:"/courses", children: [
        {label:"Основы для упражнений",key: "Gymnastics", route: "/gymnastics", component: <Gymnastics />},
        {label:"Создание основы для упражнений",key: "CreateGymnastic", route: "/create_gymnastic", component: <GymnasticCreate />},
    
        {label:"Варианты упражнений",key: "GymnasticsVariants", route: "/gymnastics_variants", component: <GymnasticVariants />},
        /*{label:"Создание варианта упражнения",key: "CreateGymnasticVariant", route: "/create_gymnastic_variant", component: <CreateGymnasticVariant />},*/
        {label:"Создание базового варианта упражнения",key: "CreateGymnasticVariantBase", route: "/create_gymnastic_variant_base", component: <CreateGymnasticVariantBase />},
    ]},


    {label:"Тесты",key: "Tests", route: "/tests", component: <Tests />},
    {label:"Создать тест",key: "CreateTests", route: "/create_test", component: <TestCreate />},

    {label:"События",key: "Events", route: "/events", component: <Events />},
    {label:"Создать событие",key: "CreateEvent", route: "/create_event", component: <EventCreate />},
]