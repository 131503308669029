import React, { useStatem, useRef } from 'react';
import 'antd/dist/antd.css';
import './style.css';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  message,
} from 'antd';
import Header from "../../components/header"
import { globalData } from '../../utils/global';
import { CreateUser } from '../../api/usersService';
import { useNavigate } from 'react-router-dom';
import { createFormData } from '../../api/apiService';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const ClientCreateForm = (data) => {
  const refFile = useRef();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    console.log(values)
    values.type = "patient";
    const formOut = createFormData(values)
    if (refFile.current.files.length > 0)
      formOut.append(
        "file", refFile.current.files[0],
      );
    const data = await CreateUser(formOut)
    if (data) {
      message.success("Клиент успешно создан!")
      navigate("/clients")
    }else{
      message.error("Клиент не создан!")
    }

    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {

    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <Form className='UserCreateForm-main'
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="horizontal"
      >
        <Form.Item label="Имя" name="name" rules={[
              {
                required: true,
              },
            ]}>
          <Input />
        </Form.Item>
        <Form.Item label="Фамилия" name="last_name" rules={[
              {
                required: true,
              },
            ]}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[
              {
                required: true,
              },
            ]}>
          <Input />
        </Form.Item>
        <Form.Item label="Пароль" name="password" rules={[
              {
                required: true,
              },
            ]}>
          <Input />
        </Form.Item>
        <Form.Item label="Телефон" name="tel">
          <Input />
        </Form.Item>
        <Form.Item label="Пол" name="sex" rules={[
              {
                required: true,
              },
            ]}>
          <Select>
            <Select.Option value="Мужской">Мужской</Select.Option>
            <Select.Option value="Женский">Женский</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Описание" name="description">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Загрузка файла" valuePropName="fileList">
            <input
              name="avatar"
              type="file"
              accept="image/jpeg, image/png"
              ref={refFile}
            />
        </Form.Item>
        <Form.Item >
          <Button type="primary" htmlType="submit">Создать</Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ClientCreateForm;