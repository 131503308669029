import { useEffect} from "react"
import Authorization from "../authorization"
import {useNavigate} from 'react-router-dom'
import {CheckIsAuthorized} from '../../utils/storage'
import { globalData } from "../../utils/global"
import logo from "../../logo.png"
const Signin = () => {
    const navigate = useNavigate();
    return (
        <Authorization logo={logo} />
    )
}

export default Signin;