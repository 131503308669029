import React , {useState, useEffect} from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Divider, Button, Select, Input } from "antd";
import {
  Radio,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
} from 'antd';
import { GetAllGymnasticVariants } from '../../../api/gymnasticsVariantsService';
const { Option } = Select;

function DynamicSubfield({field}) {
  const [variants,setVariants] = useState([]);
  const handleChange = (value) => {
    /*
    let t = gyms;
    t[index] = value
    setgyms(t)
    */
  };
  useEffect(()=>{
    GetAllGymnasticVariants().then((data)=>{
      setVariants(data)
    })
  },[])
  return (
    <Form.List name={[field.name, 'gymnasticsObj']}>
      {(gymnasticsObj, { add, remove }) => {
        return (
          <div>
            {gymnasticsObj.map((gymnastic, index) => (
              <div key={gymnastic.key}>
                <Divider>Упражнение {index + 1}</Divider>
                <Form.Item
                  {...gymnastic}
                  name={[gymnastic.name, '_id']}
                  fieldKey={[gymnastic.fieldKey, '_id']}
                  rules={[{ required: true, message: 'Missing first name' }]}
                >
                  <Select
                    onChange={handleChange}
                    style = {{
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    {variants && variants.map((item)=>{
                      return <Option value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                {gymnasticsObj.length >= 1 ? (
                  <Button
                    type="danger"
                    className="dynamic-delete-button"
                    onClick={() => remove(gymnastic.name)}
                    icon={<MinusCircleOutlined />}
                  >
                    Удалить упражнение
                  </Button>
                ) : null}
              </div>
            ))}
            <Divider />
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "60%" }}
              >
                <PlusOutlined /> Добавить упражнение
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
}

export default DynamicSubfield;
