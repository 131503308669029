import { message, Space, Table, Tag, List } from 'antd';
import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import {GetAllTests, DeleteTest} from "../../api/testsService"
import {backendApi, backendApiBase} from "../../api/apiService"
import Header from "../../components/header"
import PopoverWrapper from '../../components/popover/popover';
const Tests = (props) =>{
    const [data,setData] = useState([]);
    
    useEffect(()=>{
      GetAllTests().then((d)=>{
        let k = d.map((item, index)=>{
          item.key = index + 1;
          
          return item
        })
        setData(k); 
      })
      
    }, [])
    const columns = [
        {
          title: 'Номер',
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: 'Название',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Тип',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Вопросы',
          key: 'questions_direct',
          render:(_, record) => (
            <a>Просмотреть</a>
          )
        },
        {
          title: 'Показать',
          key: 'show',
          render: (_, record) => {
            let res =( <List>
                <p>Name: {record.name}</p>
                <p>Type: {record.type}</p>
                {
                  Object.entries(record.questions_direct).map(([key, value]) =>{
                    return (
                      <div>
                        <p> Question Name : {key.text}</p>
                        {
                            Object.entries(record.questions_direct).map(([k, val]) =>{
                                return <p> A: {val.text} </p>
                            })
                        }
                      </div>
                    )
                  })
                }
            </List> )
            return <PopoverWrapper contained = {res} />
          },
        },
        {
          title: 'Действия',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <a>Edit</a>
              <a onClick={()=>{
                console.log(record)
                DeleteTest(record).then((data)=>{
                  if (data){
                    message.success("Тест удален!")
                  }
                  GetAllTests().then((d)=>{
                    let k = d.map((item, index)=>{
                      item.key = index + 1;
                      
                      return item
                    })
                    setData(k); 
                  })
                })
              }}>Delete</a>
            </Space>
          ),
        },
      ];
      
    return (
      <>
        <Table columns={columns} dataSource={data} />

      </>
    )

}


export default Tests;
