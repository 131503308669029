import { Space, Table, Tag, Modal , List} from 'antd';
import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import {GetAll} from "../../api/coursesService"
import {backendApi, backendApiBase} from "../../api/apiService"
import Header from "../../components/header"
import { GetAllGymnasticVariants, RemoveGymnastic } from '../../api/gymnasticsVariantsService';
import PopoverWrapper from '../../components/popover/popover';
import { Player } from 'video-react';
import Popup_Own from '../../components/popup_own/popup_own';
import GymnasticVariantEditForm from '../gymnasticVariantEditForm';
const GymnasticVariantCreate = (props) =>{
    const [data,setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editModal, setEditModal] = useState({});
    const [currentSelected, setcurrentSelected] = useState({})
    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    const refreshData = () =>{
      GetAllGymnasticVariants().then((d)=>{
        let k = d.map((item, index)=>{
          item.key = index + 1;
          
          return item
        })
        setData(k); 
        console.log(k)
      })
    }
    const RemoveEventHandle = (data) => {
      console.log(data)
      RemoveGymnastic({_id: data._id}).then((data)=>{
        GetAllGymnasticVariants().then((d)=>{
          let k = d.map((item, index)=>{
            item.key = index + 1;
            
            return item
          })
          setData(k); 
          console.log(k)
        })
      }
      )
    };
    useEffect(()=>{
      GetAllGymnasticVariants().then((d)=>{
        let k = d.map((item, index)=>{
          item.key = index + 1;
          
          return item
        })
        setData(k); 
        console.log(k)
      })
      
    }, [])
    const columns = [
        {
          title: 'Key',
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: 'Название',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Повторений',
          dataIndex: 'count',
          key: 'count',
        },
        {
          title: 'Видео',
          dataIndex: 'video',
          key: 'video',
          render: (_, record) =>{
            if (record.gymnasticObj)
            return (<Popup_Own >
              <Player style={{width: "100%", height: "100%"}}>
                <source src={backendApiBase + record.gymnasticObj.gymnastic_content_url} />
              </Player>
            </Popup_Own>)
          }
        },
        {
          title: 'Создатель',
          dataIndex: 'owner',
          key: 'owner',
          render: (_, record) =>{
            if (record.owner)
              return <p>{record.owner}</p>
            else
              return <p>Без владельца(базовый)</p>
          }
        },
        {
          title: 'Показать',
          key: 'show',
          render: (_, record) => {
            return <PopoverWrapper contained = {record} />
          },
        },
        {
          title: 'Удален ли?',
          dataIndex: 'is_removed',
          key: 'is_removed',
          render: (_, record)=> (
            <Space>
              {record.is_removed?"Удален":"Не удален"}
            </Space>
          )
        },
        {
          title: 'Действия',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <a onClick={() => {
                setcurrentSelected(record)
                showModal()
              }}>Edit</a>
              <a onClick={()=>RemoveEventHandle(record)}>Delete</a>
            </Space>
          ),
        },
      ];
      
    return (
      <>
        <Table columns={columns} dataSource={data} />
        <Modal  open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer = {null}>
          <GymnasticVariantEditForm data={currentSelected} invokable={refreshData}/>
        </Modal>
      </>
        
    )

}


export default GymnasticVariantCreate;
