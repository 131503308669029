import {get, post} from "./apiService"

export function GetAllBaseCoursesWithData(){
    return post("/course/getAllWithAllData")
}
export function GetAllBase(){
    return post("/course/getBaseCoursesByAdmin")
}
export function GetAllUser(){
    return post("/course/getUserCoursesByAdmin")
}
export function UpdatePhases(data){
    return post("/course/updatePhasesByAdmin", data)
}

export function CreateBaseCourse(data){
    if (!data.phasesObj){
        data.phasesObj = []
    }
    return post("/course/createBaseWithPhases", data)
}


export function DeleteCourse(data){
    return post("/course/removeCourseByAdmin", data)
}