export function setAuthorized(){
    document.cookie = encodeURIComponent("isAuthorized") + '=' + encodeURIComponent(true);
}

export function setUnauthorized(){
    document.cookie = encodeURIComponent("isAuthorized") + '=' + encodeURIComponent(false);
}
export function CheckIsAuthorized(){
    let cookies = document.cookie.split(";")
    if (cookies.length > 0){
        const mapping = cookies.map(x => {
            let label = x.split('=')[0].replace(' ', '')
            let value = x.split('=')[1]
            let res = {}
            res[`${label}`] = value
            return res
   
        })
        let object = {}
        mapping.map((item) => {
            for (const [key, value] of Object.entries(item)) {
                object[key] = value;
            }
        })
        if (object.isAuthorized)
            return object.isAuthorized === "true" ? true : false
        return false
    }else{
        return false
    }
}
export async function GetValue(key){
    let cookies = document.cookie.split(";")
    if (cookies.length > 0){
        const mapping = cookies.map(x => {
            let label = x.split('=')[0].replace(' ', '')
            let value = x.split('=')[1]
            let res = {}
            res[`${label}`] = value
            return res
   
        })
        let object = {}
        mapping.map((item) => {
            for (const [key, value] of Object.entries(item)) {
                object[key] = value;
            }
        })
        if (object[key])
            return object[key]
        return null
    }else{
        return null
    }
}
export async function SetEmpty(key){
    document.cookie = encodeURIComponent(key) + '=' + "";
}
export async function SetValue(key, value){
    document.cookie = encodeURIComponent(key) + '=' + encodeURIComponent(value);
}