import React, { useState } from 'react';
import 'antd/dist/antd.css';
import './index.css';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu } from 'antd';
import { OmitProps } from 'antd/lib/transfer/ListBody';
import { pages } from '../../utils/global';
import { useNavigate } from 'react-router-dom';
import logo from "../../../src/logo.png"

const { Header, Content, Footer, Sider } = Layout;

function getItem({label, key, icon, children, onClick}) {
  return {
    key,
    icon,
    children,
    label,
    onClick,
  };
}



const Cabinet = (props) => {
  const navigate = useNavigate();

  const reRoute = (path) =>{
    navigate(path);
  }
  let items = pages.map((item)=>{
      let childs = item.children ? item.children.map((child) =>{
        return getItem({label: child.label, key: child.key, onClick: ()=>{ reRoute(child.route) }})
      }) : ""
      return getItem({label:item.label, key:item.key, icon:<PieChartOutlined />, onClick: () => {
        if (childs === ""){
          reRoute(item.route)
        }
      }, children: childs
      })
  })
  items.push(getItem({label:"Выход", key:"Logout", onClick: ()=> { reRoute("/logout") }}))

  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="logo"><img width="48" height="48" src={logo}/></div>
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
          }}
        > <div           
            style={{
              padding: 0,
              fontSize: 40,
            }}>{props.title}</div> </Header>
        <Content
          style={{
            margin: '0 16px',
          }}
        >
            {props.children}
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Cabinet;