import { Space, Table, Tag } from 'antd';
import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import "./style.css";
import {GetAll} from "../../api/testsService"
import {backendApi, backendApiBase} from "../../api/apiService"
import { useNavigate } from 'react-router-dom';
import { Button} from 'antd';
import { SetEmpty } from '../../utils/storage';
const Logout = (props) =>{
    const navigate = useNavigate()

    useEffect(()=>{
        props.Deauthorize();
    },[])

      
    return (
        <>
            <div className='Logout-main'>
                <div className='Logout-content'>
                    <div className='Logout-subcontent'>
                        <div>Выход из аккаунта выполнен!</div>
                        <Button type="primary" shape="round" onClick={() => {
                            SetEmpty("token");
                            navigate("/")
                        }}> Перейти на экран входа </Button>
                    </div>
                </div>
            </div>
        </>
    )

}


export default Logout;
