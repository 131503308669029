import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
//import './index.css';
import { Button, Popover, Space, List } from 'antd';


const PopoverWrapper = ({contained, isFormed}) => {
  const [open, setOpen] = useState(false);
  const [formedData, setFormedData] = useState([])
  const hide = () => {
    setOpen(false);
  };
  useEffect(()=>{
    if (!isFormed){
      let res =( <List>
          {
            Object.entries(contained).map(([key, value])=>{
              if (typeof(value) !== typeof([])  && typeof(value) !== typeof({}))
                return <p onClick={hide}>{key} : {value}</p>
            })
          }
      </List> )
      console.log(res)

    setFormedData(res)
    }

  }, [])

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={formedData}
      title="Информация"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Button type="primary">Подробнее</Button>
    </Popover>
  );
};

export default PopoverWrapper;