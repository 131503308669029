import React, { useState } from 'react';
import 'antd/dist/antd.css';
import './style.css';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
} from 'antd';
import Header from "../../components/header"
import { MinusCircleOutlined} from '@ant-design/icons';
import PhaseAdd from './subforms/phaseadd';
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const CourseCreateForm = () => {
  
  return (
    <>
      <div className='UserCreateForm-main'>
        <PhaseAdd />
      </div>
    </>
  );
};

export default CourseCreateForm;