import React from 'react';
import 'antd/dist/antd.css';
import { Button, Checkbox, Form, Input } from 'antd';
import './style.css';
import {Login} from "../../api/loginService"
import {useNavigate} from 'react-router-dom'
import {globalData} from "../../utils/global"
import {CheckIsAuthorized, setAuthorized, setUnauthorized, SetValue, GetValue} from '../../utils/storage'
const Authorization = ({logo}) => {

  const navigate = useNavigate();

  const onFinish = async (values) => {
    const data = await Login(values)
    if (data) {
      await SetValue("token", data.token)
      console.log(data)
      globalData.Authorize()
      navigate("/home")
    }

    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {

    console.log('Failed:', errorInfo);
  };

  return (
    <div className='Authorize-main'>
      <div className='Authorize-content'>
        <div className='Authorize-logo'><img width="48" height="48" src={logo} /></div>
        <div className='Authorize-title'>Sportix</div>
        <div className='Authorize-form-title'>Вход в панель администрирования</div>
        <div className='Authorize-form-subtitle'>Введите ваши email и пароль ниже</div>
        <Form
          className='Authorize-form'
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off">
          <div className='Authorize-form-label'>EMAIL</div>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Введите ваш email!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className='Authorize-form-label'>Пароль</div>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Введите ваш пароль!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className='Authorize-button'>
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Authorization;