import React, { useState } from 'react';
import 'antd/dist/antd.css';
import './style.css';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
} from 'antd';
import Header from "../../components/header"
import { MinusCircleOutlined} from '@ant-design/icons';
import CourseWithPhases from './subforms/CourseWithPhases';
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const CourseEditForm = ({data, onSubmit,refreshView}) => {
  
  return (
    <>
      <div className='UserCreateForm-main'>
        <CourseWithPhases refreshView={refreshView} data={data} onSubmit={onSubmit}/>
      </div>
    </>
  );
};

export default CourseEditForm;