import React, { useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.css';
import { SmileOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, Input, InputNumber, Modal, Typography, Space, message, notification } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CreateTest } from '../../../api/testsService';
import { useNavigate } from 'react-router-dom';

const QuestionAdd = () => {
    const navigate = useNavigate();
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  
  // reset form fields when modal is form, closed
  const useResetFormOnCloseModal = ({ form, open }) => {
    const prevOpenRef = useRef();
    useEffect(() => {
      prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
      if (!open && prevOpen) {
        form.resetFields();
      } 
    }, [form, prevOpen, open]);
  };
  
  const ModalForm = ({ open, onCancel }) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
      form,
      open,
    });
    const onOk = () => {
      
      form.submit();
    };
  
    return (
      <Modal title="Создать вопрос" open={open} onOk={onOk} onCancel={onCancel}>
        <Form form={form} layout="vertical" name="userFormTest">
          <Form.Item
            name="text"
            label="Текст вопроса"
            rules={[
              {
                required: true,
                message: 'Текст вопроса не введен!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.List name="answers" style={{width: "100%"}}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field,index) => (
              <Space key={field.key}    direction="vertical"
              size="middle"
              style={{
                display: 'flex',
              }}>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
                <Form.Item
                  {...field}
                  label={`Ответ ${index+1}`}
                  name={[field.name, 'text']}
                  rules={[
                    {
                      required: true,
                      message: 'Текст ответа не введен!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                    name="value"
                    label="Балл за ответ"
                    rules={[
                      {
                        required: true,
                        message: 'Балл за ответ не введен!',
                      },
                    ]}
                  >
                  <InputNumber />
                </Form.Item>
                
              </Space>
            ))}

            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Добавить ответ
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
        </Form>
      </Modal>
    );
  };
  
  const [questions_direct, setquestions_direct] = useState([])
  useEffect(()=>{},[questions_direct])

  const [open, setOpen] = useState(false);

  const showUserModal = () => {
    setOpen(true);
  };

  const hideUserModal = () => {
    setOpen(false);
  };

  const onFinish = (values) => {
    values.questions_direct = questions_direct
    if (values.questions_direct){
      for(let i = 0; i < values.questions_direct.length; i++){
        if (!values.questions_direct[i].answers){
          values.questions_direct[i].answers = []
        }

      }
    }
    CreateTest(values).then((data)=>{
        if(data){
            message.success("Тест успешно создан!")
            navigate("/tests")
        }
    })
    console.log('Finish:', values);
  };

  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        if (name === 'userFormTest') {
          const { basicFormTest } = forms;
          const users = basicFormTest.getFieldValue('phaseObjs') || [];
          basicFormTest.setFieldsValue({
            phaseObjs: [...users, values],
          });
          setOpen(false);
        }
      }}
    >
      <Form {...layout} name="basicFormTest" onFinish={onFinish}>
        <Form.Item label="Название" name="name"
          rules={[
            {
              required: true,
              message: 'Введите название теста!',
            },
          ]}
          >
          <Input />
        </Form.Item>
        
        <Form.Item label="Тип" name="type"         rules={[
          {
            required: true,
            message: 'Введите тип теста!',
          },
        ]}>
          <Input />
        </Form.Item>
        <Form.Item
            name="edge"
            label="Порог нормы"
            rules={[
              {
                required: true,
                message: 'Порог нормы не введен!',
              },
            ]}
          >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Вопросы"
          shouldUpdate={(prevValues, curValues) => {
            setquestions_direct(curValues.phaseObjs)
            return prevValues.phaseObjs !== curValues.phaseObjs
            }
          }
        >
          {({ getFieldValue }) => {
            const users = getFieldValue('phaseObjs') || [];
            return users.length ? (
              <ul>
                {users.map((user, index) => (
                  <li key={index} className="user">
                    {user.text}
                  </li>
                ))}
              </ul>
            ) : (
              <Typography.Text className="ant-form-text" type="secondary">
                ( Вопросы не добавлены )
              </Typography.Text>
            );
          }}
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Создать тест
          </Button>
          <Button
            htmlType="button"
            style={{
              margin: '0 8px',
            }}
            onClick={showUserModal}
          >
            Добавить вопрос
          </Button>
        </Form.Item>
      </Form>

      <ModalForm open={open} onCancel={hideUserModal} />
    </Form.Provider>
  );
};

export default QuestionAdd;