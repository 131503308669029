import React, { useEffect, useState, useRef} from 'react';
import 'antd/dist/antd.css';
import './style.css';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  message,
  Upload,
} from 'antd';
import Header from "../../components/header"
import gymnasticsService, { CreateGymnastic, GetAllGymnasticWithContent } from '../../api/gymnasticsService';
import { useNavigate } from 'react-router-dom';
import { createFormData } from '../../api/apiService';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const GymnasticAddForm = () => {
  const navigate = useNavigate();
  const [file, setfile] = useState(null)
  const refFile = useRef();

  const onFinish = async (values) => {
    console.log('Success:', values);
    const formOut = createFormData(values)
    formOut.append(
      "file", refFile.current.files[0],
    );
    CreateGymnastic(formOut).then((data)=>{
      if (data) {
        message.success(`Упражнение загружено успешно!`);
        navigate("/gymnastics")
      } else {
        message.error(`Ошибка загрузки!`);
      }
    })
  };

  const dummyRequest = ({file, onSuccess})=>{
    setTimeout(()=>{
      onSuccess("ok")
    }, 0)
  }

  const props = {
    name: 'file',
    customRequest: dummyRequest,
    acceps:".mp4",
  }
  const onChange = (info) => {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }

    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      setfile(info.file)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  }
  const onFinishFailed = (errorInfo) => {

    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Form className='UserCreateForm-main'
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
      >
        <Form.Item label="Название" name="name">
        <TextArea rows={4}/>
        </Form.Item>
        <Form.Item label="Описание" name="description">
        <TextArea rows={4}/>
        </Form.Item>
        <Form.Item label="Раздел" name="rubric">
        <TextArea rows={4}/>
        </Form.Item>
        <Form.Item label="Область тела(направленность упражнения)" name="chapter">
        <TextArea rows={4}/>
        </Form.Item>
        <Form.Item label="Задействованные группы мышц" name="muscles">
        <TextArea rows={4}/>
        </Form.Item>
        <Form.Item label="Оборудование" name="equipment">
        <TextArea rows={4}/>
        </Form.Item>
        <Form.Item label="Загрузка файла">
          <input
              name="avatar"
              type="file"
              accept="video/mp4"
              ref={refFile}
            />
        </Form.Item>
        <Form.Item >
          <Button  type="primary" htmlType="submit">Добавить</Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default GymnasticAddForm;