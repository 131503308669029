import { Space, Table, Tag } from 'antd';
import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import {GetAll} from "../../api/testsService"
import {backendApi, backendApiBase} from "../../api/apiService"
import Header from "../../components/header"
const Home = (props) =>{
    const [data,setData] = useState([]);
    
    useEffect(()=>{})

      
    return (
        <>
            <section class="purpose section">
            <h2 class="title">Добро пожаловать!</h2>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="vira-card">
                            <div class="vira-card-header">
                                <div class="card-icon">
                                    <span class="fa fa-diamond" aria-hidden="true"></span>
                                </div>
                            </div>
                            <div class="vira-card-content">
                                <h3>Приветствуем вас в кабинете администратора сервиса Sportix!</h3>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )

}


export default Home;
