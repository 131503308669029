import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
//import './index.css';
import { Button, Popover, Space, List, Modal } from 'antd';


const Popup_Own = ({children}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const showModal = () => {
    setOpen(true);
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>
        Просмотреть
      </Button>
      <Modal
        open={open}
        title="Title"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        {children}
      </Modal>
    </>
  );
};

export default Popup_Own;