import { useEffect, useState} from "react"
import {useNavigate} from 'react-router-dom'
import {CheckIsAuthorized} from '../../utils/storage'
const Rooter = () => {
    const navigate = useNavigate();
    const [r,sr] = useState()
    const check = ()=>{
        const l = CheckIsAuthorized()
        console.log(l)
        if (l){
            navigate("/home")
        }
        else{
            navigate("/signin")
        }
    }
    useEffect(()=>{
        check()
    },[])

    return (
        <>
        </>
    )
}

export default Rooter;