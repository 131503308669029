import {get, post, postFormData} from "./apiService"

export function GetUsers(){
    return post("/user/getAll")
}
export function GetDoctors(){
    return post("/user/getAllDoctors")
}
export function GetClients(){
    return post("/user/getAllPatients")
}
export function CreateUser(body){
   return postFormData("/user/create", body)
}
export function UpdateUser(body){
    return postFormData("/user/updateByAdmin", body)
 }

export function RemoveUser(body){
    return post("/user/remove", body)
}

