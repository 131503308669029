import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './style.css';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Typography,
  Upload,
  message,
} from 'antd';
import Header from "../../components/header"
import { useNavigate } from 'react-router-dom';
import { createFormData } from '../../api/apiService';
import { UpdateUser } from '../../api/usersService';

const { Text, Link } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const UserEditForm = ({setEditModal, curData}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    console.log(values)
    const formOut = createFormData(values)
    const data = await UpdateUser(formOut)
    if (data) {
      message.success("Инструктор успешно изменен!")
      navigate("/doctors")
    }else{
      message.error("Инструктор не изменен!")
    }

    console.log('Success:', values);

  };
  useEffect(()=>{
    console.log(curData)
    form.setFieldsValue(curData)

  },[curData])
  const createValidData = (data) => {
    const formData = new FormData();
    formData.append("type", "patient")
  }
  return (
    <div className='UserEditForm-back'>
      <Form className='UserEditForm-main'
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item label="_id" name="_id">
          <Input disabled/>
        </Form.Item>
        <Form.Item label="Имя*" name="name">
          <Input />
        </Form.Item>
        
        <Form.Item label="Фамилия*" name="last_name">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Email*" name="email">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Пароль*" name="password">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Телефон" name="tel">
          <Input disabled />
        </Form.Item>
        {
          curData.type === "doctor" && <Form.Item label="Кол-во назначений" name="assigns">
          <Input />
        </Form.Item>
        }
        <Form.Item label="Пол" name="sex">
          <Select disabled value={1}>
            <Select.Option value="Мужской">Мужской</Select.Option>
            <Select.Option value="Женский">Женский</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Описание">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item disabled label="Загрузка файла" valuePropName="fileList">
          <Upload action="/upload.do" listType="picture-card">
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Аватар
              </div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item >
          <Button  type="primary" htmlType="submit">Изменить</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UserEditForm;