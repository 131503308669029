import React, { useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.css';
import { SmileOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, Input, InputNumber,Select,Modal, Typography, Space, message, notification } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import GymnasticVariant from "./gymnastic"
import { CreateBaseCourse } from '../../../api/coursesService';
import { useNavigate } from 'react-router-dom';
import { GetAllGymnasticWithContent } from '../../../api/gymnasticsService';
import { GetAllGymnasticVariants } from '../../../api/gymnasticsVariantsService';
import TextArea from 'antd/lib/input/TextArea';
const { Option } = Select;
const PhaseAdd = () => {
  const navigate = useNavigate();

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  // reset form fields when modal is form, closed
  const useResetFormOnCloseModal = ({ form, open }) => {
    const prevOpenRef = useRef();
    useEffect(() => {
      prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
      if (!open && prevOpen) {
        form.resetFields();
      } 
    }, [form, prevOpen, open]);
  };
  
  const ModalForm = ({ open, onCancel }) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
      form,
      open,
    });
    const [gyms, setgyms] = useState([])
    const onOk = () => {
      
      form.submit();
    };
    const handleChangeGymnastic = (value) => {
 
    };
    const [variants,setVariants] = useState([]);
    useEffect(()=>{
      GetAllGymnasticVariants().then((data)=>{
        setVariants(data)
      })
    },[])
    return (
      <Modal title="Создать фазу" open={open} onOk={onOk} onCancel={onCancel}>
        <Form form={form} layout="vertical" name="userForm">
          <Form.Item
            name="name"
            label="Название"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea rows={4}/>
          </Form.Item>
          <Form.Item
            name="description"
            label="Описание"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea rows={4}/>
          </Form.Item>
          <Form.Item
            name="length"
            label="Длительность(в днях)"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="goals"
            label="Цели"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea rows={4}/>
          </Form.Item>
          <Form.Item
            name="criterias"
            label="Критерии окончания"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea rows={4}/>
          </Form.Item>
          <Form.List name="gymnastics" style={{width: "100%"}}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field,index) => (
              <Space key={field.key}    direction="vertical"
              size="middle"
              style={{
                display: 'flex',
              }}>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
                <Form.Item
                  {...field}
                  label={`Упражнение ${index+1}`}
                  name={[field.name, '_id']}
                  rules={[
                    {
                      required: true,
                      message: 'Упражнение не выбрано!',
                    },
                  ]}
                >
                  <Select
                    onChange={handleChangeGymnastic}
                    style = {{
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    {variants && variants.map((item)=>{
                      return <Option value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>

                
              </Space>
            ))}

            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Добавить упражнение
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
        </Form>
      </Modal>
    );
  };
  
  const [phases, setphases] = useState([])
  useEffect(()=>{},[phases])

  const [open, setOpen] = useState(false);

  const showUserModal = () => {
    setOpen(true);
  };

  const hideUserModal = () => {
    setOpen(false);
  };

  const onFinish = (values) => {
    values.phasesObj = phases
    CreateBaseCourse(values).then((data)=>{
      if(data){
        message.success(`Базовый курс создан!`);
        navigate("/base_courses")
      }else{
        message.error("Базовый курс не создан!")
      }
    })
    console.log('Finish:', values);
  };

  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        if (name === 'userForm') {
          const { basicForm } = forms;
          const users = basicForm.getFieldValue('phaseObjs') || [];
          basicForm.setFieldsValue({
            phaseObjs: [...users, values],
          });
          setOpen(false);
        }
      }}
    >
      <Form {...layout} name="basicForm" onFinish={onFinish}>
        <Form.Item label="Название" name="name"
          rules={[
            {
              required: true,
              message: 'Введите название курса!',
            },
          ]}
          >
          <TextArea rows={4}/>
        </Form.Item>
        <Form.Item label="Описание" name="description"         rules={[
          {
            required: true,
            message: 'Введите описание курса!',
          },
        ]}>
          <TextArea rows={4}/>
        </Form.Item>
        <Form.Item label="Цель" name="injury" rules={[
          {
            required: true,
            message: 'Введите цели(травму) курса!',
          },
        ]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Группа" name="tag" rules={[
          {
            required: true,
            message: 'Введите группу(тэг) курса!',
          },
        ]}>
          <TextArea rows={4}/>
        </Form.Item>
        <Form.Item label="Направленность" name="body_area" rules={[
          {
            required: true,
            message: 'Введите направленность(область действия) курса!',
          },
        ]}>
          <TextArea rows={4}/>
        </Form.Item>
        <Form.Item
          label="Фазы"
          shouldUpdate={(prevValues, curValues) => {
            setphases(curValues.phaseObjs)
            return prevValues.phaseObjs !== curValues.phaseObjs
            }
          }
        >
          {({ getFieldValue }) => {
            const users = getFieldValue('phaseObjs') || [];
            return users.length ? (
              <ul>
                {users.map((user, index) => (
                  <li key={index} className="user">
                    <Avatar icon={<UserOutlined />} />
                    {user.name}
                  </li>
                ))}
              </ul>
            ) : (
              <Typography.Text className="ant-form-text" type="secondary">
                ( Фазы не добавлены )
              </Typography.Text>
            );
          }}
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Создать курс
          </Button>
          <Button
            htmlType="button"
            style={{
              margin: '0 8px',
            }}
            onClick={showUserModal}
          >
            Добавить фазу
          </Button>
        </Form.Item>
      </Form>

      <ModalForm open={open} onCancel={hideUserModal} />
    </Form.Provider>
  );
};

export default PhaseAdd;