import { message, Space, Table, Tag } from 'antd';
import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import {DeleteCourse, GetAllUser} from "../../api/coursesService"
import {backendApi, backendApiBase} from "../../api/apiService"
import Header from "../../components/header"
const UserCourses = (props) =>{
    const [data,setData] = useState([]);
    
    useEffect(()=>{
      GetAllUser().then((d)=>{
        let k = d.map((item, index)=>{
          item.key = index + 1;
          
          return item
        })
        setData(k); 
        console.log(k)
      })
      
    }, [])
    const columns = [
        {
          title: 'Номер',
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: 'Название',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Описание',
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: 'Тэг',
          dataIndex: 'tag',
          key: 'tag',
        },
        {
          title: 'Удален ли?',
          dataIndex: 'is_removed',
          key: 'is_removed',
          render: (_, record) => (
            <Space size="middle">
              {record.is_removed? "Удален": "Не удален"}
            </Space>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <a onClick={()=>{handleDelete(record)}}>Delete</a>
            </Space>
          ),
        },
      ];
      const handleDelete = (data) => {
        DeleteCourse({_id: data._id}).then((data)=>{
          if (data){
            message.success("Курс удален!")
            GetAllUser().then((d)=>{
              let k = d.map((item, index)=>{
                item.key = index + 1;
                
                return item
              })
              setData(k); 
            })
          }
        })
      }
    return (
      <>
        <Table columns={columns} dataSource={data} />
      </>
        
    )

}


export default UserCourses;
