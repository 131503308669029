import { Space, Table, Tag, Modal, message, Popover } from 'antd';
import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import {GetClients, GetDoctors, RemoveUser} from "../../api/usersService"
import Header from "../../components/header"
import UserEditForm from '../userEditForm';
import PopoverWrapper from '../../components/popover/popover';
const Clients = (props) =>{
    const [data,setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editModal, setEditModal] = useState({});
    const [curData, setCurData] = useState({})
    const showModal = (cur) => {
      setIsModalOpen(true);
      setCurData(cur)
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    useEffect(()=>{
      GetClients().then((d)=>{
        let k = d.map((item, index)=>{
          item.key = index + 1;
          return item
        })
        setData(k); 
      })
      
    }, [])
    const columns = [
        {
          title: 'Номер',
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: 'Тип',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Имя',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Фамилия',
          dataIndex: 'last_name',
          key: 'last_name',
        },
        {
          title: 'Показать',
          key: 'show',
          render: (_, record) => (
            <PopoverWrapper contained = {record} />
          ),
        },
        {
          title: 'Действия',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <a onClick={()=>{showModal(record)}}>Edit</a>
              {/*}
              <a  onClick={()=>{
                RemoveUser(record).then((data)=>{
                  if (data){
                    message.success("Клиент удален!")
                    GetClients().then((d)=>{
                      let k = d.map((item, index)=>{
                        item.key = index + 1;
                        return item
                      })
                      setData(k); 
                    })
                  }
                })
              }}>Delete</a>
            */}
            </Space>
          ),
        },
      ];
      
    return (
      <>
        <Table columns={columns} dataSource={data} />
        <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <UserEditForm setEditModal={setEditModal} curData={curData}/>
        </Modal>
      </>
        
    )

}


export default Clients;
