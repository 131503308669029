import React, { useEffect, useState, useRef} from 'react';
import 'antd/dist/antd.css';
import './style.css';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  message,
  Upload,
} from 'antd';
import Header from "../../components/header"
import gymnasticsService, { UpdateGymnastic } from '../../api/gymnasticsService';
import { useNavigate } from 'react-router-dom';
import { createFormData } from '../../api/apiService';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const GymnasticEditForm = ({invokables, invokable, data}) => {
  const navigate = useNavigate();
  const [file, setfile] = useState(null)
  const refFile = useRef();
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    const formOut = createFormData(values)
    formOut.append("_id", data._id)
    Object.entries(formOut).map((i)=>{
      console.log(formOut)
    })
    UpdateGymnastic(formOut).then((d)=>{
      if (d) {
        message.success(`Упражнение обновлено успешно!`);
        invokable()
        navigate("/gymnastics")
      } else {
        message.error(`Ошибка обновления!`);
      }
    })
  };
  useEffect(()=>{
    console.log(data)
    form.setFieldsValue(data)

  },[data])
  const onFinishFailed = (errorInfo) => {

    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <Form className='UserCreateForm-main'
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
        form={form}
      >
        <Form.Item label="Название" name="name">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Описание" name="description">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Раздел" name="rubric">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Область тела(направленность упражнения)" name="chapter">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Задействованные группы мышц" name="muscles">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Оборудование" name="equipment">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item >
          <Button  type="primary" htmlType="submit">Изменить</Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default GymnasticEditForm;