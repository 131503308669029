import { Space, Table, Tag, Modal } from 'antd';
import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import {GetAll, GetAllGymnasticWithContent, RemoveGymnastic} from "../../api/gymnasticsService"
import {backendApi, backendApiBase} from "../../api/apiService"
import Header from "../../components/header"
import Popup_Own from '../../components/popup_own/popup_own';
import PopoverWrapper from '../../components/popover/popover';
import ReactPlayer from 'react-player';
import { Player } from 'video-react';
import GymnasticEditForm from '../gymnasticEditForm';
const Gymnastics = (props) =>{
    const [data,setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editModal, setEditModal] = useState({});
    const [currentSelected, setcurrentSelected] = useState({})
    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const RemoveEventHandle = (data) => {
      console.log(data._id)
      RemoveGymnastic({_id: data._id}).then((data)=>{
        GetAllGymnasticWithContent().then((d)=>{
          let k = d.map((item, index)=>{
            item.key = index + 1;
            
            return item
          })
          setData(k); 
        })
      })
    };
    const refreshData = () =>{
      GetAllGymnasticWithContent().then((d)=>{
        let k = d.map((item, index)=>{
          item.key = index + 1;
          
          return item
        })
        setData(k); 
      })
    }
    useEffect(()=>{
      GetAllGymnasticWithContent().then((d)=>{
        let k = d.map((item, index)=>{
          item.key = index + 1;
          
          return item
        })
        setData(k); 
      })
    }, [])
    const columns = [
        {
          title: 'Номер',
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: 'Название',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Описание',
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: 'Удален ли?',
          dataIndex: 'is_removed',
          key: 'is_removed',
          render: (_, record)=> (
            <Space>
              {record.is_removed?"Удален":"Не удален"}
            </Space>
          )
        },
        {
          title: 'Видео',
          dataIndex: 'gymnastic_content_url',
          key: 'gymnastic_content_url',
          render: (text)=> (
            <Popup_Own >
              <Player style={{width: "100%", height: "100%"}}>
                <source src={backendApiBase + text} />
              </Player>
            </Popup_Own>
          )
        },
        {
          title: 'Показать',
          key: 'show',
          render: (_, record) => (
            <PopoverWrapper contained = {record} />
          ),
        },
        {
          title: 'Действия',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <a onClick={() => {
                setcurrentSelected(record)
                showModal()
              }}>Edit</a>
              <a onClick={()=>RemoveEventHandle(record)}>Delete</a>
            </Space>
          ),
        },
      ];
      
    return (
      <>
        <Table columns={columns} dataSource={data} />
        <Modal  open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
          <GymnasticEditForm data={currentSelected} invokable={refreshData}/>
        </Modal>
      </>
        
    )

}


export default Gymnastics;
