import React, { useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.css';
import { SmileOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, Input, InputNumber,Select,Modal, Typography, Space, message, notification } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UpdatePhases } from '../../../api/coursesService';
import { useNavigate } from 'react-router-dom';
import { GetAllGymnasticWithContent } from '../../../api/gymnasticsService';
import { GetAllGymnasticVariants } from '../../../api/gymnasticsVariantsService';
import DynamicField from './DynamicField';
const { Option } = Select;
const { TextArea } = Input;
const CourseWithPhases = ({data, onSubmit, refreshView}) => {
  const navigate = useNavigate();

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  
  const [phases, setphases] = useState([])
  const [form] = Form.useForm();
  const formList = useRef();
  const [fields, setfields] = useState([])

  const [open, setOpen] = useState(false);
  useEffect(()=>{
    console.log(data)
    form.setFieldsValue(data)

  },[data])
  const showUserModal = () => {
    setOpen(true);
  };

  const hideUserModal = () => {
    setOpen(false);
  };

  const onFinish = (values) => {
    let o = {}
    let v = {}
    Object.assign(v,values)
    o.courseData = v;
    for (let p = 0; p <  v.phasesObj.length; p++){
      if (v.phasesObj[p]._id == undefined){
        v.phasesObj[p]._id = ""
        if (v.phasesObj[p].gymnasticsObj == undefined){
          v.phasesObj[p].gymnasticsObj = []
        }
      }
    }
    o.phases = v.phasesObj
    
    console.log("result: ", values)
    UpdatePhases(o).then((data)=>{
      if(data){
        message.success(`Базовый курс изменен!`);
        onSubmit()
        if (refreshView){
          refreshView();
        }
        navigate("/base_courses")
      }else{
        message.error("Базовый курс не изменен!")
      }
    })
  };

  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        form.resetFields();
      }}
    >
      <Form {...layout} name="basicForm" onFinish={onFinish} form={form}>
      <Form.Item name="_id"
          >
        </Form.Item>
        <Form.Item label="Название" name="name"
          rules={[
            {
              required: true,
              message: 'Введите название курса!',
            },
          ]}
          >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Описание" name="description"         rules={[
          {
            required: true,
            message: 'Введите описание курса!',
          },
        ]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Цель" name="injury" rules={[
          {
            required: true,
            message: 'Введите цели(травму) курса!',
          },
        ]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Группа" name="tag" rules={[
          {
            required: true,
            message: 'Введите группу(тэг) курса!',
          },
        ]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Направленность" name="body_area" rules={[
          {
            required: true,
            message: 'Введите направленность(область действия) курса!',
          },
        ]}>
          <TextArea rows={4} />
        </Form.Item>
         <DynamicField />
        <Form.Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Form.Provider>
  );
};

export default CourseWithPhases;