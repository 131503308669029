import { Space, Table, Tag, Modal } from 'antd';
import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import {GetAllEvents, RemoveEvent} from "../../api/eventsService"
import {backendApi, backendApiBase} from "../../api/apiService"
import Header from "../../components/header"
import EventEditForm from '../eventEditForm';
import PopoverWrapper from '../../components/popover/popover';
const Events = (props) =>{
    const [data,setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editModal, setEditModal] = useState({});
    const showModal = () => {
      setIsModalOpen(true);
    };
    const RemoveEventHandle = (data) => {
      console.log(data)
      RemoveEvent({_id: data._id}).then((data)=>{
        GetAllEvents().then((d)=>{
          let k = d.map((item, index)=>{
            item.key = index + 1;
            return item
          })
          setData(k); 
          console.log(k)
        })
      })
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    useEffect(()=>{
      GetAllEvents().then((d)=>{
        let k = d.map((item, index)=>{
          item.key = index + 1;
          
          return item
        })
        setData(k); 
        console.log(k)
      })
      
    }, [])
    const columns = [
        {
          title: 'Номер',
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: 'Имя',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Тип',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Текст',
          dataIndex: 'text',
          key: 'text',
        },
        {
          title: 'Показать',
          key: 'show',
          render: (_, record) => (
            <PopoverWrapper contained = {record} />
          ),
        },
        {
          title: 'Действия',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <a onClick={showModal}>Edit</a>
              <a onClick={()=>RemoveEventHandle(record)}>Delete</a>
            </Space>
          ),
        },
      ];
      
    return (
      <>
        <Table columns={columns} dataSource={data} />
        <Modal  open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <EventEditForm setEditModal={setEditModal}/>
        </Modal>
      </>
        
    )

}


export default Events;
