import React, { useState } from 'react';
import 'antd/dist/antd.css';
import './style.css';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
} from 'antd';
import Header from "../../components/header"
import GymnasticAddForm from "../gymnasticAddForm"
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const GymnasticCreate = () => {

  return (
    <>
      <GymnasticAddForm />
    </>
  );
};

export default GymnasticCreate;