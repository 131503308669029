import React, { useState } from 'react';
import 'antd/dist/antd.css';
import './style.css';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
} from 'antd';
import Header from "../../components/header"
import CreateGymnasticVariantForm from '../createGymnasticVariantForm';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const CreateGymnasticVariant = () => {

  return (
    <>
      <CreateGymnasticVariantForm />
    </>
  );
};

export default CreateGymnasticVariant;