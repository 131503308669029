import { message, Modal, Space, Table, Tag } from 'antd';
import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import {DeleteCourse, GetAllBase} from "../../api/coursesService"
import {backendApi, backendApiBase} from "../../api/apiService"
import Header from "../../components/header"
import CourseEditForm from '../courseEditForm';
const BaseCourses = (props) =>{
    const [data,setData] = useState([]);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [dataT, setdataT] = useState({name:"name"});
    useEffect(()=>{
      refreshView()
    }, [])
    const refreshView = () => {
      GetAllBase().then((d)=>{
        let k = d.map((item, index)=>{
          item.key = index + 1;
          
          return item
        })
        setData(k); 
      })
    }
    const handleEdit = (data) => {
      console.log(data)
      for( let p = 0; p <  data.phasesObj.length; p++){
      let initialValue = ""
        if (data.phasesObj[p].goals.length == 1){
          data.phasesObj[p].goals = data.phasesObj[p].goals[0]
        }else{
          data.phasesObj[p].goals = data.phasesObj[p].goals.reduce(
            (previousValue, currentValue) => previousValue + ", " + currentValue,
            initialValue
          );
        }

      initialValue = ""
      if (data.phasesObj[p].criterias.length == 1){
        data.phasesObj[p].criterias = data.phasesObj[p].criterias[0]
      }
      else{
          data.phasesObj[p].criterias = data.phasesObj[p].criterias.reduce(
            (previousValue, currentValue) => previousValue + ", " + currentValue,
            initialValue
          );
        }
      }

      setdataT(data)
      setIsEditOpen(true)
    }
    const handleDelete = (data) => {
      DeleteCourse({_id: data._id}).then((data)=>{
        if (data){
          message.success("Базовый курс удален!")
          GetAllBase().then((d)=>{
            let k = d.map((item, index)=>{
              item.key = index + 1;
              
              return item
            })
            setData(k); 
          })
        }
      })
    }

    const columns = [
        {
          title: 'Номер',
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: 'Название',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Описание',
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: 'Тэг',
          dataIndex: 'tag',
          key: 'tag',
        },
        {
          title: 'Удален ли?',
          dataIndex: 'is_removed',
          key: 'is_removed',
          render: (_, record) => (
            <Space size="middle">
              {record.is_removed? "Удален":"Не удален"}
            </Space>
          ),
        },
        {
          title: 'Действия',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <a onClick={()=>{
                handleEdit(record)
              }}>Edit</a>
              <a onClick={()=>{handleDelete(record)}}>Delete</a>
            </Space>
          ),
        },
      ];
    const handleCancel = () => {
      setIsEditOpen(false);
      refreshView();

    }
    return (
      <>
        <Table columns={columns} dataSource={data} />
        <Modal title="Редактировать курс" open={isEditOpen} onCancel={handleCancel} footer={[<></>]}> <CourseEditForm refreshView={refreshView} data={dataT} onSubmit={()=>setIsEditOpen(false)}/></Modal>
      </>
        
    )

}


export default BaseCourses;
