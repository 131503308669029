import { Space, Table, Tag } from 'antd';
import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import {backendApi, backendApiBase} from "../../api/apiService"
import ClientCreateForm from "../clientCreateForm"
import Header from "../../components/header"
import { GetClients } from '../../api/usersService';
const ClientCreate = (props) =>{

    return (
      <>
        <ClientCreateForm  />
      </>

    )

}


export default ClientCreate;
