import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './style.css';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  message,
} from 'antd';
import Header from "../../components/header"
import { CreateGymnasticVariant } from '../../api/gymnasticsVariantsService';
import { GetAllGymnasticWithContent } from '../../api/gymnasticsService';
import { useNavigate } from 'react-router-dom';
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
const CreateGymnasticVariantForm = () => {
  const [variants,setVariants] = useState(["Каждый день","Через день"]);
  const [gyms,setGyms] = useState([]);
  const [doctors,setDoctors] = useState([]);
  const navigate = useNavigate();

  const handleChange = (value) => {
 
  };
  useEffect(()=>{
    GetAllGymnasticWithContent().then((data)=>{
      setGyms(data)
    })
  },[])
  useEffect(()=>{
  
  })

  const onFinish = async (values) =>{
    console.log(values)
    CreateGymnasticVariant(values).then((data)=>{
      if(data){
        message.success(`Вариант упражнения создан!`);
        navigate("/gymnastics_variants")
      }else{
        message.error("Вариант упражнения не создан!")
      }
    })
    
  }
  const onFinishFailed = async (values) =>{
    console.log(values)
  }
  return (
    <>
      <Form className='UserCreateForm-main'
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
      >
        <Form.Item label="Свое название" name="name">
        <TextArea rows={4}/>
        </Form.Item>
        <Form.Item label="Количество повторений" name="count">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Режим повторений" name="repeatType">
          <Select
            onChange={handleChange}
            style = {{
              marginLeft: 5,
              marginRight: 5,
            }}
          >
            {variants && variants.map((item, index)=>{
              return <Option value={index+1}>{item}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Основа" name="gymnastic">
          <Select
            onChange={handleChange}
            style = {{
              marginLeft: 5,
              marginRight: 5,
            }}
          >
            {gyms && gyms.map((item)=>{
              return <Option value={item._id}>{item.name}</Option>
            })}
          </Select>
        </Form.Item>
        <Form.Item >
          <Button  htmlType="submit" type="primary">Создать</Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateGymnasticVariantForm;