import {get, post, postFormData} from "./apiService"

export function GetAllGymnasticVariants(){
    return post("/gymnastic_variant/getAll")
}

export function CreateGymnasticVariant(data){
    return post("/gymnastic_variant/createByAdmin", data)
}

export function CreateBaseGymnasticVariant(data){
    return post("/gymnastic_variant/createBase", data)
}
export function RemoveGymnastic(data){
    return post("/gymnastic_variant/gymnasticsVariantRemoveSoft", data)
}
export function UpdateGymnasticVariant(data){
    return postFormData("/gymnastic_variant/updateGymnastic", data)
}