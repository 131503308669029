import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
} from 'antd';
import { MinusCircleOutlined} from '@ant-design/icons';
import { GetAll, GetAllGymnasticVariants } from '../../../api/gymnasticsVariantsService';
import { GetAllGymnasticWithContent } from '../../../api/gymnasticsService';

const { Option } = Select;


const GymnaticVariant = ({gyms, setgyms, index}) => {

  const handleChange = (value) => {
    /*
    let t = gyms;
    t[index] = value
    setgyms(t)
    */
  };
  const [variants,setVariants] = useState([]);
  useEffect(()=>{
    GetAllGymnasticVariants().then((data)=>{
      setVariants(data)
    })
  },[])
    return (
      <>
      <Select
        onChange={handleChange}
        style = {{
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        {variants && variants.map((item)=>{
          return <Option value={item._id}>{item.name}</Option>
        })}
      </Select>
    </>

    )
};

export default GymnaticVariant;