import {get, post} from "./apiService"

export function GetAllEvents(){
    return post("/event/getAll")
}

export function CreateEvent(data){
    return post("/event/create", data)
}

export function RemoveEvent(data){
    return post("/event/remove", data)
}
